<template>
  <div class="home-container">
    <div class="linear-bg"></div>
      <div class="banner">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v, key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular" />
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>	
      <div class="notice-bar">
        <van-notice-bar class="notice-swipe" left-icon="bullhorn-o" background="#ffffff" color="#7e5678"
          :text="this.notice" />
        <div class="linear-gradient"></div>
      </div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">

        <van-list
                    v-model="loading"
                    :finished="finished"
                    :offset="50"
                    :immediate-check="false"
                    :finished-text="1"
                    @load="onLoad"
                >
                <div class="movie_list_n" v-if="movielist_1.length > 0">
            <div class="movie-list-n-item" v-for="(v,key) in movielist_1" :key="key" @click="profile(v.id)">
              <div class="movie-list-n-title">
                {{v.xuanfei_name}}
              </div>
              <div class="movie-list-n-img">
                <van-image
                  fit="cover"
                  :src="v.img_url"
                  class="movie-list-n-img" :data-src="v.img_url"  alt=""  v-lazy="v.img_url"
                />
                <!-- <img> -->
                <div class="movie-list-n-lab">{{v.addres2}}</div>
              </div>
              <div class="movie-list-n-item-bottom">
                <div class="movie-n-time-div">
                 <img class="movie-list-ico-loves" v-for="item in 6" :key="item" src="/images/nHome/ico-love.png"  alt="" >
                  <div class="movie-list-addr">
                    <img class="movie-list-ico-addr" src="/images/nHome/ico-addr.png"  alt="" >
                    {{v.address}}
                  </div>
                  <div class="movie-list-money">
                    <img class="movie-list-ico-money" src="/images/nHome/ico-money.png"  alt="" >{{v.price}}</div>

                </div>
              </div>
            </div>
          </div>
   </van-list>

      </van-pull-refresh>
</div>
</template>

<script>
export default {
  data() {
    return {
      notice: "Đang tải......",
      basicData: [],
      movielist_1:[],
      isLoading: false,
      gameitem: [{}, {}, {}, {}],
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true
        }
      }	  
    };
  },
  methods:{
    gotoMenu(router){
      this.$router.replace(router)
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast("Thành công!");
      }, 500);
    },
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
        this.basicData = res.data;
		this.getBanner(this.basicData);//获取banner
		this.getNotice(this.basicData);
      })

    },
	profile(id) {
			this.$router.push({ path: '/profile?id=' + id });
	},	
	getxuanfeilist() {
			this.$http({
				method: 'get',
				url: 'xuanfeihome',
				data: { id: 1 }
			}).then(res => {
				this.movielist_1 = res.data;
			});
	},
    getBanner(data) {
      this.banners = data.banners;
    },
    getNotice(data) {
      this.notice = data.notice;
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res => {
        this.gameitem = res.data
      })
    }
  },
  mounted () {

  },
  created() {
    this.getBasicConfig();
	this.getxuanfeilist();
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size:30px;
@movie-list-item-bottom-size:25px;
::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}
.notice-swipe {
  width: calc(100% - 20px);
  height: 65px;
  font-size: @notice-bar-size;
}
.linear-bg{
  height: 200px;
  background: linear-gradient(
      270deg,#e6c3a1,#f905e5);
}
.home-container{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.movie_list_n{
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  padding-bottom: 120px;
}
.movie-list-n-item{
 width: calc(50% - 11px);
 padding: 10px;
 background-color: #fff;
border: 1px solid #fff;
 color: #000;
 margin: 20px 5px;
 border-radius: 20px;
 padding: 10px;
}
.movie-list-n-item-bottom{

}
.movie-n-time-div{
margin-top: 20px;
}
.movie-list-n-title{
  // color: #f905e5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
  font-weight: bold;
}
.movie-list-n-img{
width: 330px;
height: 330px;
position: relative;
}
.movie-list-n-lab{
  display: inline;
  position: absolute;
  top: 15px;
  left: 15px;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;  background-color: #f905e5;
  width: 315px;
  overflow: hidden;
}
.movie-list-ico-loves{
  display: inline-block;
  width: 40px;
}


.movie-list-notice{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.movie-list-ico-notice{
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}
.movie-list-addr{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.movie-list-ico-addr{
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}
.movie-list-money{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.movie-list-ico-money{
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}
.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.banner {
  width: 100%;
  margin-top: -23%;
}

.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}

</style>
